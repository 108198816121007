import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { LinkIn } from '../../components/Link'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0,0,0,3),
    textAlign: 'left',
    background: 'transparent',
  },
  paperYear: {
    padding: theme.spacing(0,6,0,0),
    textAlign: 'right',
    background: 'transparent'
  },
  text: {
    '&::selection': {
      backgroundColor: '#666'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  year: {
    '&::selection': {
      backgroundColor: '#666'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  link: {
    '&:hover': {
      textDecoration: 'none'
    }
  }
  }));

export default function Page(props) {
  const classes = useStyles()
  const { projects, years} = props
  const [ year, setYear ] = useState(
    years.length > 0 ? years[years.length-1].name : null
  )
  return (
      <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={9}>
          <Paper className={classes.paper} elevation={0}>
          {projects.map((obj, i) => (
            (year === obj.year || !year ) && (
              <Typography paragraph
              variant='subtitle1'
                className={classes.text}
              >
              <LinkIn key={i}
                to={obj.to}
              >
                {obj.name}
              </LinkIn>
              </Typography>
            )
          ))}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paperYear} elevation={0}>
          {years.map((obj, i) => (
            <Typography key={i}
              color={year === obj.name ? 'secondary' : 'primary'}
              className={classes.year}
              style={{cursor: 'pointer'}}
              to={obj.to}
              variant='subtitle1'
              onClick={() => setYear(obj.name)}
            >
              {obj.name}
            </Typography>
          ))}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export function ProjectListUpcoming(props) {
  const classes = useStyles()
  const { projects } = props
  return (
      <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <Paper className={classes.paper} elevation={0}>
          {projects.map((obj, i) => (
              <Typography paragraph
                className={classes.text}
                color='primary'
                variant='subtitle1'
              >
                {obj.name}
              </Typography>
          ))}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export function ProjectListArchive(props) {
  const classes = useStyles()
  const { projects } = props
  return (
      <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <Paper className={classes.paper} elevation={0}>
          {projects.map((obj, i) => (
              <Typography paragraph
                className={classes.text}
                color='primary'
                variant='subtitle1'
              >
              <LinkIn 
                to={obj.to}
                className={classes.link}
              >
                {obj.name}
              </LinkIn>
              </Typography>
          ))}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}