import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Layout from '../layouts/Base'
import { ProjectListUpcoming } from '../components/ProjectList'

import background from '../assets/images/karrabing/background.png'


const useStyles = makeStyles((theme) => ({
  root: {
    margin: '100px 200px 0px 200px',
    [theme.breakpoints.down('lg')]: {
      margin: '100px 200px 0px 200px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '100px 90px 0px 90px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '140px 30px 0px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '140px 20px 0px 20px',
    },
  },
}));

const projects = [
  //  {
  //    name: 'Abtin Sarabi, Parcelles 7, 1 – 21 October 2021',
  //    to: '/',
  //    year: '2021',
  //  },
]
const years = [

]

export default function Page(props) {
  const classes = useStyles()

  return (
    <Layout page='upcoming' background={background}>
      <div
        className={classes.root}
      >
      <ProjectListUpcoming
        projects={projects}
      />
      </div>
    </Layout>
  )
}
